function storeArticle() {

    $('.js_storeArticle').click(function (e) {

        e.preventDefault();

        var button    = $(this);
        var action    = button.data('action');
        var url       = button.attr('href');
        var deleteUrl = button.data('delete-url');

        if (action === 'store'){

            $.ajax({
                type: "POST",
                url: url,
                data: {
                    'app_model': button.data('app'),
                    'pk': button.data('pk'),
                    'csrfmiddlewaretoken': button.data('csrf')
                },

                success: function(data) {
                    button.data('action', 'remove');
                    button.html('Remove article');
                },

                error: function(data) {
                    // todo: standardni alert
                }

            });

        } else if (action == 'remove'){

            $.ajax({
                type: "DELETE",
                url: deleteUrl,

                beforeSend: function(xhr) {
                    xhr.setRequestHeader("X-CSRFToken", button.data('csrf'));
                },

                success: function(data) {
                    button.data('action', 'store');
                    button.text('Store article');
                },

                error: function(data) {
                    // todo: standardni alert
                }

            });
        }

    });
};



function sendCorrection() {

    $('.js_submitCorrection').click(function (e) {

        e.preventDefault();

        var msg  = $('.js_modalMsg');
        var form = $(this).parent('form');
        var url  = form.attr('action');
        var data = form.serializeArray();

        $.ajax({
            type: "POST",
            url: url,
            data: data,

            success: function(data) {
                msg.html('<div class="modal__message modal__message--success">Hvala.</span>');

                setTimeout(function(){
                    $('.js_modalClose').trigger('click');
                }, 2000);
            },

            error: function(data) {
                msg.html('<div class="modal__message modal__message--error">Dogodila se greška.</span>');
            }
        });

    });

};

sendCorrection();
storeArticle();