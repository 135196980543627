$(function() {

    //jshint multistr:true

    /* IN ARTICLE PREROLL
    *******************************************************/
    $.fn.inView = function(){

        var $article = $('.detail_content');
        var portion = 4; // parseInt( $article.data('videoautoplay_p'));

        var win = $(window);
        var headerOffset = 250;
        obj = $(this);

        // detekcije jel u drugoj trećini ekrana
        var scrollPosition = win.scrollTop() + ( win.height() / portion ) + headerOffset; // pomak za trećinu + header
        var visibleArea = win.scrollTop() + ( win.height() / portion * 2 ) + headerOffset; // gornja trećina ekrana
        var objEndPos = obj.offset().top + obj.outerHeight();

        return(visibleArea >= objEndPos && scrollPosition <= objEndPos ? true : false);
    };

    var setVimeo = function(objID, file_url ){

        f_width = '100%';
        f_height = '100%';

        // http://vimeo.com/77474520
        var vimeoMovieID = file_url.substr( file_url.lastIndexOf('/') + 1 );
        if( vimeoMovieID.indexOf('id=') !== -1 ) {
            vimeoMovieID = file_url.substr( file_url.lastIndexOf('clip_id=') + 8 );
        }

        var vimeoInner = $(`<iframe src="http://player.vimeo.com/video/${vimeoMovieID}?color=00adef&portrait=0&badge=0"
        width="${f_width}" height="${f_height}" frameborder="0"
        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);

        var vimeoHolder = $(`#${objID}`);

        var paddTop = 30;
        if( vimeoHolder.hasClass('inline_jwplayer')){
            paddTop = 0;
        }

        vimeoHolder.css({
            'width': f_width,
            'height': f_height,
            'padding-top': paddTop
        }).append( vimeoInner );
    };

    var setYouTube = function( objID, file_url, autoplay ){  // 0 - false

        if (autoplay === undefined) {
              autoplay = 0;
        }

        if ( autoplay === true) {
              autoplay = 1;
        }

        var f_width  = '100%',
            f_height = '100%';

        // https://www.youtube.com/watch?v=uIf9GdCzY_Y
        var ytMovieID = file_url.split('?v=')[1];

        if( !ytMovieID ) {

            // http://www.youtube.com/v/uIf9GdCzY_Y
            ytMovieID = file_url.split('/v/')[1];

        }
        // http://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com

        var ytInner = $(`<iframe id="ytplayer" type="text/html" width="${f_width}" height="${f_height}"
          src="http://www.youtube.com/embed/${ytMovieID}?autoplay=${autoplay}&origin=${PUBLITZER.site_url}"
          frameborder="0" style="position: absolute;" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>`);

        var ytHolder = $(`#${objID}`);

        var paddTop = 0;
        if( ytHolder.hasClass('inline_jwplayer')){
            paddTop = 0;
        }

        ytHolder
            .html('')
            .css({
                'display': 'block',
                'position': 'relative',
                'height': '0',
                'width': f_width,
                'padding-top': paddTop,
                'padding-bottom': '56.25%',
                'margin-bottom': '20px' // malo razmaka ispod playera
            })
            .append( ytInner );
    };

    var setJWplayer = function(objID, file_url, poster_url, autoplay){

        jwplayer.key="g1st/18c6koHgHREMSYddDH3sd3cb7eWu8V+4Q==";

        var prerollTag = '';
        var aspectRatio = "16:9";

        if (objID == "video") {
            aspectRatio = "16:9"; // samo za specijal box
            prerollTag = "";
        }

        if ( $('body').data('bodyextrainfo', 'no_preroll') ) {
            prerollTag = "";
        }

        jwplayer( objID ).setup({
            width: '100%',
            file: file_url,
            image: poster_url,
            aspectratio: aspectRatio,
            skin: 'beelden',  // beelden, bekle, five, glow, modieus, roundster, six, stormtrooper, vapor
            autostart: autoplay,
            ga: {},
            advertising: {
                client: 'vast',
                tag: prerollTag,
                admessage: 'Nastavak videa za XX sekundi.',
                skipoffset: '10',
                skipmessage: 'Skip in xx s',
                skiptext: 'Skip'
            }
        });

        if( autoplay ) {
            setTimeout(function(){
                jwplayer( objID ).play( true );
            }, 300);
        }
    };

    var getVideoType = function ( file_url ){

        var type = '';

        if( file_url.indexOf('vimeo') != -1 ){
            return 'vimeo';
        }

        if( file_url.indexOf('youtube') != -1 ){
            return 'youtube';
        }

        if( file_url.indexOf('youtu.be') != -1 ){
            return 'youtube';
        }

        return 'CDN';
    };

    function articleVideoPlayer( $el, event, autoplay ) {

        if( event !== '' ){ event.preventDefault(); }
        if( autoplay === undefined ){ autoplay = false; }

        var $singlePlayer = $el;

        // offset top makes error with offset()
        if ( !$singlePlayer.length ) { return; }

        var videoURL    = $singlePlayer.data('href'),
            videoPoster = $singlePlayer.data('poster'),
            videoID     = $singlePlayer.attr('id'),
            videoType   = getVideoType( videoURL );

        switch ( videoType ) {

            case 'vimeo':
                setVimeo ( videoID, videoURL, autoplay );
                break;

            case 'youtube':
                setYouTube ( videoID, videoURL, autoplay );
                break;

            default:
                setJWplayer ( videoID, videoURL, videoPoster, autoplay );
                break;
        }
    }

    // TODO: vidjeti kako je najbolje kontrolirati ponašanje autoplaya
    function inlineAutoplayInit( $playerSlot, playerID ) {

        var $article = $('#article_content .main_gutter');
        if ( $article.data('videoautoplay') === false ) { return; }

        var sensitivity = 100; // parseInt( $article.data('videoautoplay_s'));

        var playerOffset = $( '#' + playerID ).offset(),
            playerInstance = jwplayer( $playerSlot.attr('id') ),
            init = true;
            var userInteracted = false;

        $playerSlot.addClass('autoplay_init');
        playerInstance.setVolume( 33 );  // ******  mora biti isti kao getVolume

        playerInstance.onSeek(function(){
            userInteracted = true;
        });

        playerInstance.onVolume(function(){
            userInteracted = true;
        });

        playerInstance.onDisplayClick(function(){
            userInteracted = true;
        });

        $( window ).scroll( $.throttle(500, checkTopPosition) );

        // showing when share buttons disappeare
        function checkTopPosition() {

            // korisnik je pojačavao/smanjivao video video
            if ( !userInteracted ){

                // jesmo li došli do sredine
                if ( $( '#' + playerID ).inView() ){

                    init = false;
                    playerInstance.play( true );

                } else {

                    playerInstance.pause( true );

                }
            }
        }
    }

    function inlinePlayerInit() {

        var placementID = $('#article_content').find('.main_gutter').data('video-placement-id');

        if($('.inline_jwplayer').length ){

            $('.inline_jwplayer').show().each(function(){

                var $playerSlot = $(this);
                $playerSlot.addClass('inlinePlayerInitFN');

                if ( getVideoType( $playerSlot.data('href') ) != 'CDN' ) {

                    articleVideoPlayer($playerSlot, '');
                    return;
                }

                $playerSlot.attr('data-placement-id', placementID);

                $playerSlot
                    .find('> span').remove().end()
                    .wrap('<div class="responsive_inline_container" id="container_' + $playerSlot.attr('id') + '">').wrap('<div>');

                articleVideoPlayer($playerSlot, '');
                inlineAutoplayInit($playerSlot, 'container_' + $playerSlot.attr('id') );
            });
        }
    }

    function inlinePrerollVideo () {

        var articleContent = $('.article__text'),
            contentHeight,
            prerollTag,
            file_url,
            playerInstance,
            playerContainer,
            prerollExit = false;

        prerollTag = '';
        file_url = '/static/video/blank_video.mp4';

        var scrollEnabled = false;

        $(window).scroll( $.throttle(500, checkTopPosition) );

        setInterval(function() {

            if( scrollEnabled ) {
                scrollEnabled = false;
            }

        }, 100);

        function checkTopPosition() {

            playerContainer = $('#inline_preroll_player');
            playerInstance = jwplayer('inline_preroll_player');
            scrollEnabled = true; // scroll trotting

            if(playerContainer.length > 0) {

                if ( playerContainer.inView() ){
                    // ako je video vec jednom zavrsio ili ako je skipan
                    // nece se ponovo pokrenuti
                    if(prerollExit === false) {

                        playerInstance.play( true );

                    }

                } else {

                    // ovo ne radi jer se reklama ne moze pauzirati
                    playerInstance.pause( true );

                }
            }
        }

        setTimeout(function() {

            contentHeight = articleContent.height();

            if(contentHeight >= 1000){

                // ako je clanak visi od 1000px na dno upucaj inline container
                articleContent.append(`
                    <div id="inline_preroll_player_container" style="position: relative; width:100%; height:0px; visibility:hidden; transition: height 0.5s; overflow: hidden;" href="#">
                        <div id="inline_preroll_player" style="position: absolute; width: 100%; height: 100%;"></div>
                    </div>`);

                // inicijalizacija, autostart: false
                jwplayer('inline_preroll_player').setup({
                    file: file_url,
                    width: '100%',
                    height: '100%',
                    skin: 'glow',  // beelden, bekle, five, glow, modieus, roundster, six, stormtrooper, vapor
                    autostart: false,
                    mute: true,
                    ga: {},
                    advertising: {
                        client: 'vast',
                        tag: prerollTag,
                        admessage: 'Nastavak videa za XX sekundi.',
                        skipoffset: '10',
                        skipmessage: 'Skip in xx s',
                        skiptext: 'Skip'
                    }
                });

                jwplayer().onAdImpression(function(event){

                    $('#inline_preroll_player_container').css({
                        'visibility': 'visible',
                        'padding-bottom': '56.3%',
                        'margin-bottom': '20px'
                    });

                    $('#inline_preroll_player').css({
                        'position': 'absolute'
                    });

                });

                jwplayer().onAdComplete(function(){

                    $('#inline_preroll_player_container').css({
                        'height': '0px'
                    });

                    prerollExit = true;

                });

                jwplayer().onAdError(function(){

                    console.log('onAdError');

                    $('#inline_preroll_player_container').css({
                        'height': '0px'
                    });

                    prerollExit = true;

                });

                jwplayer().onAdSkipped(function(){

                    $('#inline_preroll_player_container').css({
                        'height': '0px'
                    });

                    prerollExit = true;

                });
            }
        }, 1500);
    }

    // Specijal box video (homepage)
    function initSpecialBoxPlayer() {

        var $inlineJWPlayer = $('.js_SpecialBoxVideoPlayer'),
            $counter = 0;

        // offset top makes error with offset()
        if ( !$inlineJWPlayer.length ) { return; }

        $('.js_SpecialBoxVideoPlayerTrigger').on('click', function(evt) {

            evt.preventDefault();

            if ( $counter ===  0 ) {

                $(this).find('.gallerybtn--play').remove();
                articleVideoPlayer($inlineJWPlayer, '', true);
            }

            $counter =+ 1;
        });
    }

    $('.js_articleVideoPlayer').on( 'click', function ( event ) {
        articleVideoPlayer ( $(this), event, true );
    });

    function videoArticleInitPlayer() {

        articleVideoPlayer ( $('.js_startArticleVideo'), '', true );

    }

    inlinePrerollVideo ();
    initSpecialBoxPlayer();
    inlinePlayerInit();
    videoArticleInitPlayer();
});
