
/*jshint multistr: true */

require('./modules/userscontent.js')
require('./modules/comments.js')
require('./modules/video_player.js')

$(function () {
    const mobileBreakpoint = window.matchMedia("(max-width: 1023px)");

    // vanjski urlovi u target="_blank"
    function fixOutsideUrl() {
        $(".article__content a[href^='http']").attr('target', '_blank');
    }

    // slanje ispravka za članak
    function newArticleCorrect($toggleBtn) {

        var $articleText = $('.js_articleText'),
            $sendCorrectedArticle = $('.js_sendCorrectedArticle'),
            errorTekst = $toggleBtn.data('m_error'),

            app = $toggleBtn.data('m_meta-app-name'),
            model = $toggleBtn.data('m_meta-model-name'),
            pk = $toggleBtn.data('m_meta-pk'),
            articleTitle = $toggleBtn.data('m_meta-article-title');

        $sendCorrectedArticle.on('click', function (e) {
            e.preventDefault();

            if ($articleText.val().length === 0) { return; }

            $.ajax({

                type: "POST",
                url: $toggleBtn.data('url'),
                data: { 'title': articleTitle, 'correction': $articleText.val(), 'model': model, 'app': app, 'pk': pk },

                success: function (data) {

                    $('.mfp-content .js_modalTitle').text('Članak uspješno prijavljen');
                    $('.mfp-content .js_articleText').slideUp();

                    setTimeout(function () {

                        var magnificPopup = $.magnificPopup.instance;
                        magnificPopup.close();

                    }, 3000);
                },

                statusCode: {

                    201: function () {
                        console.info('Uspješno');
                    },

                    403: function () {
                        $('.mfp-content .js_modalTitle').text(errorTekst).css('color', '#F00');
                        $('.mfp-content .js_articleText').slideUp();
                    },

                    408: function () {
                        console.warn('Problem sa serverom - Pokušajte kasnije');
                    },
                    409: function () {

                        $('.mfp-content .js_modalTitle').text(errorTekst).css('color', '#F00');
                        $('.mfp-content .js_articleText').slideUp();
                    },
                    500: function () {
                        console.warn('Problem sa serverom - Pokušajte kasnije');
                    },

                    502: function () {
                        console.warn('Problem sa serverom - Pokušajte kasnije');
                    }
                }
            });
        });
    }

    // zatvaranje otvorenog modala
    function setModalClose() {

        $('.js_modalClose').addClass('js_modalCloseFN');

        $('.js_modalClose').on('click', function (evt) {

            if ($('body').hasClass('standalone_modal') || $('body').hasClass('modal--standalone')) {
                return;
            } else {
                evt.preventDefault();
            }

            var magnificPopup = $.magnificPopup.instance;
            magnificPopup.close();

        });
    }

    // otvaranje modala za ispravak članka (template mora postojati u članku)
    function initArticleCorrection() {

        $('.js_articleCorrect').magnificPopup({
            modal: true,
            items: {
                src: '#article_correction',
                type: 'inline',
                // Allow opening popup on middle mouse click.
                // Always set it to true if you don't provide alternative source in href.
                midClick: true
            },
            callbacks: {
                open: function () {
                    setModalClose();
                    newArticleCorrect($('.js_articleCorrect'));
                }
            }
        });
    }

    /**
     * Edits the gallery URL so that each xinline gallery
     * always has a close button that leads to the article from which it was opened.
     */
    function editXinlineGalleryUrl() {

        const xinlineGallery = $('.xinline-gallery');
        $(xinlineGallery).each(function (index) {
            const newGalleryUrl = `${xinlineGallery[index].href}&close_url=${currentUrl}`;
            $(xinlineGallery[index]).attr('href', newGalleryUrl);
        });
    }

    /**
     * Opens each image in the article body as a gallery (full screen mode).
     * More info about imageLightbox plugin: https://osvaldas.info/image-lightbox-responsive-touch-friendly
     */
    function imageLightbox() {
        const initLightbox = ($selector) => {
            return $selector.imageLightbox({
                selector: 'id="imagelightbox"',   // string;
                allowedTypes: 'png|jpg|jpeg|gif', // string;
                animationSpeed: 300,              // integer;
                preloadNext: true,                // bool;            silently preload the next image
                enableKeyboard: true,             // bool;            enable keyboard shortcuts (arrows Left/Right and Esc)
                quitOnEnd: false,                 // bool;            quit after viewing the last image
                quitOnImgClick: false,            // bool;            quit when the viewed image is clicked
                quitOnDocClick: true,             // bool;            quit when anything but the viewed image is clicked
                onStart: false,                   // function/bool;   calls function when the lightbox starts
                onEnd: false,                     // function/bool;   calls function when the lightbox quits
                onLoadStart: false,               // function/bool;   calls function when the image load begins
                onLoadEnd: false,                 // function/bool;   calls function when the image finishes loading
                button: true
            });
        };

        const $els = $('.imagelightbox');
        const hoverClass = 'imagelightbox-on-hover';

        // add classes and href-s according to mobile breakpoint
        const checkBreakpointAndEnableDisable = () => {
            if (mobileBreakpoint.matches) {
                if ($els.hasClass(hoverClass)) {
                    $els.removeClass(hoverClass);
                    $els.each((_, el) => {
                        $(el).removeAttr('href');
                    });
                }
            } else {
                if (!$els.hasClass(hoverClass)) {
                    $els.addClass(hoverClass);
                    $els.each((_, el) => {
                        const $el = $(el);

                        $el.attr('href', $el.find("img").data('href'));
                    });
                }
            }
        };

        // Initialize Lightbox on selected elements 
        initLightbox($els);

        // Check breakpoint once initially
        checkBreakpointAndEnableDisable();
        // Check breakpoint on each resize
        $(window).resize(function () {
            checkBreakpointAndEnableDisable();
        });
    }

    function wrapImageInATagForLightboxFunctionality() {
        $('div.js_lightbox img:not(div.js_lightbox>a>img)').each(function () {
            $(this).parent().wrap(function () {
                return '<a class="imagelightbox"/>';
            });
        });
    }

    function AddMagnifyingButtonToIframe() {
        if (!mobileBreakpoint.matches && $('.magnifier').length === 0) {
            let buttonToInsert = '<button class="magnifier" type="button"><i class="icon icon-magnify"></i> POVEČAJ</button>'
            $('.article__content p > iframe').after(buttonToInsert);

            $('.magnifier').each(function () {
                $(this).magnificPopup({
                    items: {
                        src: $(this).prev().clone().first(),
                        type: 'inline',
                        midClick: true
                    },
                    closeMarkup: '<button class="magnifier close_button" title="Zapri"><i class="icon icon-close"></i></button>',
                    closeBtnInside: false
                });
            });
        } else if (mobileBreakpoint.matches && $('.magnifier').length > 0) {
            $('.magnifier').remove();
        }
    }

    fixOutsideUrl();
    initArticleCorrection();
    editXinlineGalleryUrl();

    if ($.isFunction($.fn.imageLightbox)) {
        wrapImageInATagForLightboxFunctionality();
        imageLightbox();
    } else {
        console.warn("Plugin 'imagelightbox' not loaded.");
    }

    AddMagnifyingButtonToIframe();
    $(window).resize(function () {
        AddMagnifyingButtonToIframe();
    });
});
